import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SuperHttpClientProvider } from '@paella-front/ngx-super-http-client';
import { IUser } from 'app/data/models/IUser';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _user$: BehaviorSubject<IUser> = new BehaviorSubject(null);

  /////////////////
  // Constructor //
  /////////////////

  constructor(
    private $shttp: SuperHttpClientProvider
  ) { }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  ///////////////////////
  // Public Accessors //
  ///////////////////////

  public getErrorResponse(errorResponse: HttpErrorResponse): any {
    if (errorResponse.error && errorResponse.error.length > 0 && errorResponse.error[0].message) {
      return errorResponse.error[0].message;
    } else {
      return 'Ocurrió un error inesperado';
    }
  }

  public get user$(): Observable<IUser> {
    return this._user$.asObservable();
  }

  public setUser$(_user): any {
    this._user$.next(_user);
  }

  public getMe(): Observable<IUser> {
    return this.$shttp.use('app').get(`/user/me`);
  }

  public removeFile(type: string, idFileReference: number, idFile: number, idEstab?: number): Observable<any> {
    const url = type === 'estab' ? `/estab/${idEstab}/${idFileReference}/file/${idFile}` : `/ref/${idFileReference}/file/${idFile}`;
    return this.$shttp.use('app').delete<any>(url);
  }

  public orderFiles(type: string, data: { idFileReference: number, idFileList: any[], sortOrderList: any[] }): Observable<any> {
    return this.$shttp.use('app').put<any>(`/${type}/orderFiles`, data);
  }
}
