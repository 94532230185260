import { Component, OnInit } from '@angular/core';
import { IUser } from 'app/data/models/IUser';
import { ApiService } from 'app/core/services/api.service';
import { SearchService } from 'app/core/services/search.service';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'pae-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor(
    private searchService: SearchService,
    private $api: ApiService
  ) {}

  ngOnInit() {
    this.searchService.search$.subscribe((value: string) => {
      if (value.length >= 3) {
        console.log(value);
      }
    });

    /* this.$api.getMe().pipe(
      untilDestroyed(this),
      tap((data: IUser) => {
        this.$api.setUser$(data);
      })
    ).subscribe(); */
  }
}
