// Core modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// App modules
import { PipesModule } from './core/pipes/pipes.module';
import { AppDatabaseModule } from './app-database.module';
import { AppHttpClientModule } from './app-http-client.module';

// External modules
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { AppComponent } from './app.component';

// Others
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { ConfirmationDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { AppRoutes } from './app.routing';

// Locales
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

// Register locale ES
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
    useHash: false
}),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    HttpClientModule,
    PipesModule,
    NgbModule,
    AppDatabaseModule,
    AppHttpClientModule
  ],
  providers: [
    ConfirmationDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
