import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { DatabaseProvider } from '@paella-front/ngx-database';
import { SuperHttpClientProvider } from '@paella-front/ngx-super-http-client';
import { IInvite } from 'app/data/models/IInvite';

interface Token {
  current: number;
  jwt: string;
  refresh: string;
  type: string;
}

const STORAGE_TOKEN = 'token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private oauthHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${environment.PUBLIC_JWT}`
  });
  private _token$: BehaviorSubject<Token> = new BehaviorSubject(null);

  constructor(
    private $db: DatabaseProvider,
    private $$shttp: SuperHttpClientProvider,
  ) { }

  ///////////////////////
  // Private Functions //
  ///////////////////////

  private async setToken(value: Token): Promise<void> {
    await (this.$db.default as any).set(STORAGE_TOKEN, value);
    if (!!value) {
      this.$$shttp.use('app').headers.set('Authorization', `${value.type} ${value.jwt}`);
      this.$$shttp.use('oauth').headers.set('Authorization', `${value.type} ${value.jwt}`);
      // this.$$shttp.use('proxy').headers.set('Authorization', `${value.type} ${value.jwt}`);
    } else {
      this.$$shttp.use('app').headers.reset();
      this.$$shttp.use('oauth').headers.reset();
    }

    this._token$.next(value);
  }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  private get $shttp() {
    return this.$$shttp.use('oauth');
  }

  //////////////////////
  // Public Accessors //
  //////////////////////

  public get token$(): Observable<Token> {
    return this._token$.asObservable();
  }

  //////////////////////
  // Public Functions //
  //////////////////////

  public getErrorResponse(errorResponse: HttpErrorResponse): any {
    if (errorResponse.error && errorResponse.error.length > 0 && errorResponse.error[0].message) {
      return errorResponse.error[0].message;
    } else {
      return 'Ocurrió un error inesperado';
    }
  }

  public async getToken(): Promise<Token> {
    return await (this.$db.default as any).get(STORAGE_TOKEN);
  }

  public isOauthUrl(url: string): boolean {
    return url.includes(environment.server.oauth);
  }

  public async isSignedIn(): Promise<boolean> {
    const token = await this.getToken();

    if (!!token) {
      if (!this.$$shttp.use('app').headers.get('Authorization')) {
        await this.setToken(token);
      }

      return true;
    } else { return false; }
  }

  /**
   * data = { email: email; }
   */
  public async recovery(data: any): Promise<void> {
    try {
      await this.$shttp.post<unknown>('/open/recovery', data, { headers: this.oauthHeaders }).toPromise();
    } catch (e) {
      await this.logout();
      throw e;
    }
  }

  /**
   * @param {any} data.code string
   * @param {any} data.password string
   *
   * ----- OR -----
   *
   * @param {any} data.username string
   */
  public async recover(data: any): Promise<void> {
    try {
      await this.$shttp.put<unknown>('/open/recovery', data, { headers: this.oauthHeaders }).toPromise();
    } catch (e) {
      await this.logout();
      throw e;
    }
  }

  public async refreshToken(): Promise<Token> {
    const oldToken = await this.getToken();
    if (!oldToken) {
      throw new Error('AuthService.refreshToken(): No token found!');
    }

    const data = { refresh: oldToken.refresh };
    const newToken = await this.$shttp.post<Token>('/refresh', data).toPromise();
    await this.setToken(newToken);

    return newToken;
  }

  public login(username: string, password: string) {
    return this.$shttp.post<any>(`/open/login`, { username, password }, { headers: this.oauthHeaders })
      .pipe(map((response: Token) => {
        this.setToken(response);
        return response;
      }));
  }

  public register(isAcceptedTerms = true, username: string, password: string, token: string) {
    return this.$shttp.put<any>(`/open/invite/${token}`, { isAcceptedTerms, username, password }, { headers: this.oauthHeaders });
  }

  public async logout(): Promise<void> {
    await this.setToken(null);
  }

  public getMe(): Observable<unknown> {
    return this.$shttp.get(`/user/me`);
  }

  public getInvitaciones(page: number, perPage: number): Observable<IInvite[]> {
    return this.$shttp.get(`/admin/invite?page=${page}&perPage=${perPage}`, { observe: 'response' })
  }

  public enviarInvitacion(data: { email: string, dataJSON: {} }): Observable<unknown> {
    return this.$shttp.post(`/admin/invite/send`, data);
  }

  public reenviarInvitacion(data: { id: number }): Observable<unknown> {
    return this.$shttp.post(`/admin/invite/forward`, data);
  }
}
