// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  domain: 'https://test.hipermercode.eu/',
  server: {
    app: 'https://test.hipermercode.eu/api',
    oauth: 'https://oauthv2.hipermercode.eu/api'
  },
  PUBLIC_JWT: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyZGFkZmU1YzdjN2E0YjY4OGJkNTZmMGVmMzBiZmQ4OCIsImlzcyI6Imh0dHBzOi8vaGlwZXJtZXJjb2RlLmNvbSIsImV4cCI6MjMyNjk1ODg0OX0.TZO_I7PrnQxpcEjO5dmUKN94d28t9ihCj6NxpMiVFKM'
};
