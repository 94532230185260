import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  private _search$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() {}

  setValue(value: string) {
    this._search$.next(value);
  }

  public get search$(): Observable<string> {
    return this._search$.asObservable();
  }
}
